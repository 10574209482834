<template>
  <section class="hero" :class="heroClass">
    <div class="hero-body">
      <div :class="containerClass">
        <div class="columns" :class="columnsClass">
          <div class="column" :class="firstColumnClass">
            <h1 class="title">
              <span class="icon" v-if="icon"><fa :icon="['fal', icon]" /></span>
              <span>{{ titleTr }} <slot name="title-extra"></slot></span>
            </h1>
            <h2 class="subtitle is-hidden-mobile">{{ subtitleTr }}</h2>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
    <sqr-progress v-show="isLoading" />
  </section>
</template>

<style scoped>
.loader {
  display: inline-block;
  margin-left: 1rem;
}
.is-light.is-bold .loader {
  border-color: black;
  border-right-color: transparent;
  border-top-color: transparent;
}
.icon {
  //margin-left: 2rem;
  padding-left: 1rem;
  padding-right: 2rem;
}
</style>

<script>
import color from './mixins/color';
import size from './mixins/size';
import SqrProgress from './SqrProgress';

export default {
  name: 'sqr-hero',
  mixins: [color, size],
  components: { SqrProgress },
  props: {
    icon: String,
    title: String,
    titleRaw: String,

    columnsClass: { type: [String, Object], default: () => '' },
    firstColumnClass: { type: [String, Object], default: () => '' },

    subtitle: String,
    subtitleRaw: String,

    isBold: Boolean,

    isFluid: Boolean,

    isLoading: Boolean,
  },
  computed: {
    titleTr() {
      return this.titleRaw || this.$t(this.title);
    },
    subtitleTr() {
      return this.subtitleRaw || this.$t(this.subtitle);
    },
    heroClass() {
      return Object.assign({}, this.sizeClass, this.colorClass, {
        'is-bold': this.isBold,
      });
    },
    containerClass() {
      return {
        container: !this.isFluid,
        'is-fluid': this.isFluid,
      };
    },
  },
};
</script>
