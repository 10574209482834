<template>
  <main>
    <sqr-hero
      title="translations_title"
      subtitle="translations_subtitle"
      color="dark"
    />
    <section class="section">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>{{ $t('translations_key') }}</th>
            <th>{{ $t('translations_en') }}</th>
            <th>{{ $t('translations_fr') }}</th>
            <th>{{ $t('translations_de') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="key in keys" :key="key">
            <td>
              <span class="tag light">{{ key }}</span>
            </td>
            <td :class="{ 'is-danger': !$te(key, 'en') }">
              {{ $t(key, 'en') }}
            </td>
            <td :class="{ 'is-danger': !$te(key, 'fr') }">
              {{ $t(key, 'fr') }}
            </td>
            <td :class="{ 'is-danger': !$te(key, 'de') }">
              {{ $t(key, 'de') }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </main>
</template>

<script>
import SqrHero from '@/sqrd/SqrHero';
import en from '@/locales/en';

export default {
  name: 'Translations',
  components: {
    SqrHero,
  },
  computed: {
    keys() {
      return Object.keys(en.messages);
    },
  },
};
</script>
